import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Reveal from '../reveal'
import Img from 'gatsby-image'

class MobileGallery extends Component {
  render() {
    const { title, description, text, className, images } = this.props
    return (
      <Reveal>
        <div className={`cs-project-mobile-gallery ${className}`}>
          <div className='container'>
            <div className='col-md-6 description-cont'>
              <h2>{title}</h2>
              <p className="description" dangerouslySetInnerHTML={{ __html: description}}></p>
              <p className="description-smaller" dangerouslySetInnerHTML={{ __html: text}}></p>
            </div>
            <div className='box-cont'>
              {images.nodes.map((img, i) => (
                <div className="box" key={'webImages' + i}>
                <Img
                    fluid={img.childImageSharp.fluid}
                    alt={title} />
                </div>
              ))} 
              </div>
            </div>
        </div>
      </Reveal>
    )
  }
}

MobileGallery.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
MobileGallery.defaultProp = {
    className: 'light',
}

export default MobileGallery