import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Layout from '../../../../../components/layout'
import { removeNumberPrefix } from '../../../../../utilities'
import MobileGallery from '../../../../../components/case-studies/MobileGallery'
import { graphql } from 'gatsby'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'
import Reveal from '../../../../../components/reveal'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter
    
    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Tvorba eshopu Roomfactory'}
          description={"Prípadová štúdia"}
          description2={'Nový moderný eshop na mieru s kopou funkcií a prepracovaným UX pre úspešnú slovenskú firmu s nábytkom.'}
          text={'Klient potreboval profesionálny eshop. Našou úlohou teda bolo vyšperkovať do dokonalosti UX dizajn a venovať náležitú pozornosť programovaniu všetkých možností v administrácii  na mieru. '}
          img={data.landingImage}
          pageMode={true}
          noShadow={true}
          what_we_done={[
            { text: 'ux & webdesign' },
            { text: 'Kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (php + javascript)' },
          ]}/>

        <AboutProject
          title={'Úspešný eshop nevznikne sám od seba'}
          text={'Vytvorenie eshopu, ktorý bude zarábať si vyžaduje profesionálny prístup.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Klient nás oslovil, pretože ich starý eshop mal zjavné chybičky krásy i funkčnosti. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila. Zamerali sme sa hlavne na dôležitosť <a class="blue-link" href="/ux-ui-dizajn">UX dizajnu</a> a jeho vplyv na nákupné správanie potenciálnych zákazníkov.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Úloha bola jasná. Vytvoriť oku lahodiaci moderný <a class="blue-link" href="/eshop-na-mieru">eshop</a> s intuitívnym dizajnom, ktorý nebude pôsobiť rušivo, ale vkusne, minimalisticky a nadčasovo. Musí byť už na prvý klik zrejmé, že ide s dobou, a zároveň mať najlepšiu technologickú úroveň <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Pri projektoch sa často navrhuje aj <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme sa pustili do wireframov (rozloženia stránok) a finálneho <a class="blue-link" href="/webdizajn">webdizajnu</a>. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich programátorov.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme <b>ako zvýšiť ich tržby</b>.',
            }
          ]}
        />

        <PagesShowcase
          fixedTitleWidth={true}
          title={'Webový dizajn na mieru'}
          description={'Profesionálny eshop sa bez neho nezaobíde.'}
          text={'Roomfactory je stelesnením jednoduchého, no sofistikovaného a nadčasového eshopu. Zákazníci sa v ňom dokážu intuitívne orientovať, čomu napomáha kvalitný UX dizajn webu. Aj vďaka tomu ho pravidelne navštevujú všetci, ktorí hľadajú moderný eshop s kvalitným exkluzívnym nábytkom.'}
          projectDetail={'www.roomfactory.sk'}//TODO
          pages={data.pages.nodes.map(node => ({ image: node, name: removeNumberPrefix(node.name) }))}
        />
        
        {/* All images */}
        <Reveal>
        <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
          <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="roomfactory" />
          </div>
        </Reveal>

        <Reveal>
          <MobileGallery
            className="brown"
            title={'Mobilná verzia'}
            description={'Väčšina návštevníkov webstránky prichádza cez mobily. Myslíme na to.'}
            text={"Vytvoriť responzívny web do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='blue-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na smartfónoch (mobile first), tabletoch a ďalších rozlíšeniach. <br/><br/>Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastný portál a chce, aby mu prinášal čo najväčší zisk."}
            projectDetail={'/nase-prace/webstranky/eshopy/primadonna-collection'}//TODO
            images={data.mobileGallery}
          />
        </Reveal>

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.roomfactory.sk" target='_blank' className='link-secondary'>www.roomfactory.sk</a>
        </div>
         
        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Sladučké ovocie'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/eshopy/sladucke-ovocie'}
        />

      </Layout>
    )
  }
}

export const query = graphql`{
   project: markdownRemark(fields: {slug: {regex: "/roomfactory/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/roomfactory/visual.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pages: allFile(filter: { relativePath: {regex: "/(case-studies/roomfactory/pages/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/roomfactory/mobileGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}
`

export default Letenky
